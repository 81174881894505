@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('fonts/font-size.css');
@import url('fonts/font-weight.css');

/** Uncomment this import if needed */
@import url('form/form-control-select.css');
@import url('background/container.css');
@import url('hr/hr-or.css');
@import url('hr/hr.css');
/* @import url('scrollbar/scrollbar.css'); */
@import url('loading/global-loading.css');
@import url('custom/custom-style.css');
@import url('custom/table.css');
@import url('border/border.css');
@import url('custom/progress-bar-ratings.css');
/* Bootstrap overrides */
@import url('overrides/button.css');
@import url('overrides/background.css');
@import url('overrides/text.css');

:root {
    --primary: #003466;
    --secondary: #cbdfc6;
    --success: #28a745;
    --danger: #dc3545;
    --warning: #ffc107;
    --info: #17a2b8;
    --light: #f8f9fa;
    --dark: #000000;
    --text-light: #fff;
    --text-dark: #000000;
    --header: #003466;
    --progress-yellow: #ffd700;

    --border: #ADADAD;
    --low-prim: #cbdfc68f;
    --white: #fff;
    --gray: #808080;

    --font-primary: 'Poppins';
}

body {
    font-family: var(--font-primary) !important;
    background-color: var(--secondary) !important;
    color: var(--dark) !important;
}

p {
    margin: 0 !important;
}

a {
    text-decoration: none;
    color: var(--text-dark);
    transition: 0.3s;
}

section {
    padding: 0 1rem 0 1rem;
}

.a-hover:hover {
    opacity: 70% !important;
}

.cursor-pointer {
    cursor: pointer;
}

.overflow-wrap {
    overflow-wrap: break-word !important;
}

.equal-height-col {
    display: flex !important;
    flex-direction: column !important;
}

.notif-circle {
    width: 15px;
    height: 15px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: var(--light);
    color: var(--primary);
    border: 1px solid var(--dark);
    font-weight: 700;
    position: absolute;
    margin-left: 13px;
}