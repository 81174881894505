.border-bottom {
    border-bottom: 1px solid var(--border) !important;
}

.card-top-border {
    border-top: 5px solid var(--primary) !important;
}

.border-dotted {
    border: 1px dotted var(--gray);
}